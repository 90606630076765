import Vue from 'vue'
import VueRouter from 'vue-router'
import Container from '../views/Dashboard'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Container,
    children: [
      {
        path: '',
        name: 'dash.index',
        meta: { auth: true },
        component: () => import('../views/Overview/Wrapper.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/Overview/Overview.vue'),
            meta: { auth: true, title: 'Overview', routeIdentifier: 'overview' },
          }
        ],
      },
      {
        path: 'financial',
        meta: { auth: true },
        component: () => import('../views/Financial/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.financial.index',
            component: () => import('../views/Financial/Financial.vue'),
            meta: { auth: true, title: 'Financial', routeIdentifier: 'financial' },
          }
        ],
      },
      {
        path: 'marketing-revenue',
        meta: { auth: true },
        component: () => import('../views/MarketingRevenue/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.marketing-revenue.index',
            component: () => import('../views/MarketingRevenue/MarketingRevenue.vue'),
            meta: { auth: true, title: 'MarketingRevenue', routeIdentifier: 'marketing-revenue' },
          }
        ],
      },
      {
        path: 'platform',
        meta: { auth: true },
        component: () => import('../views/Platform/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.platform.index',
            component: () => import('../views/Platform/Platform.vue'),
            meta: { auth: true, title: 'Platform', routeIdentifier: 'platform' },
          }
        ],
      },
      {
        path: 'marketing-costs',
        meta: { auth: true },
        component: () => import('../views/MarketingCosts/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.marketing-costs.list',
            component: () => import('../views/MarketingCosts/List.vue'),
            meta: { auth: true, title: 'Marketing Costs', routeIdentifier: 'marketing-costs' },
          },
          {
            path: 'create',
            name: 'dash.marketing-costs.create',
            component: () => import('../views/MarketingCosts/Upsert.vue'),
            meta: { auth: true, title: 'Create Marketing Cost', routeIdentifier: 'marketing-costs' },
          },
          {
            path: ':id',
            name: 'dash.marketing-costs.update',
            component: () => import('../views/MarketingCosts/Upsert.vue'),
            meta: { auth: true, title: 'Update Marketing Cost', routeIdentifier: 'marketing-costs' },
          },
        ],
      },
      {
        path: 'conversions',
        meta: { auth: true },
        component: () => import('../views/Conversions/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.conversions.table',
            component: () => import('../views/Conversions/ConversionsTable.vue'),
            meta: { auth: true, title: 'Conversions', routeIdentifier: 'conversions' },
          },
        ],
      },
      {
        path: 'time-to-sale',
        meta: { auth: true },
        component: () => import('../views/TimeToSale/Wrapper.vue'),
        children: [
          {
            path: 'impression-kits',
            name: 'dash.time-to-sale.impression-kits',
            component: () => import('../views/TimeToSale/ImpressionKits.vue'),
            meta: { auth: true, title: 'Impression Kits', routeIdentifier: 'impression-kits' },
          },
          {
            path: 'aligners',
            name: 'dash.time-to-sale.aligners',
            component: () => import('../views/TimeToSale/Aligners.vue'),
            meta: { auth: true, title: 'Aligners', routeIdentifier: 'aligners' },
          },
          {
            path: 'scan-appointments',
            name: 'dash.time-to-sale.scan-appointments',
            component: () => import('../views/TimeToSale/ScanAppointments.vue'),
            meta: { auth: true, title: 'Scan Appointments', routeIdentifier: 'scan-appointments' },
          }
        ],
      },
      {
        path: 'reports',
        meta: { auth: true },
        component: () => import('../views/Reports/Wrapper.vue'),
        children: [
          {
            path: 'postcode',
            name: 'dash.reports.postcode',
            component: () => import('../views/Reports/PostcodeReport.vue'),
            meta: { auth: true, title: 'Postcode Report', routeIdentifier: 'postcode-report' },
          },
          {
            path: 'tabeo',
            name: 'dash.reports.tabeo',
            component: () => import('../views/Reports/TabeoReport.vue'),
            meta: { auth: true, title: 'Tabeo Report', routeIdentifier: 'tabeo-report' },
          },
        ],
      },
    ]
  },
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Container.vue'),
    children: [
      {
        path: 'not-allowed',
        name: 'auth.not-allowed',
        meta: { auth: true, title: 'Not Allowed' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/NotAllowed.vue')
      }, {
        path: 'login',
        name: 'auth.login',
        meta: { auth: false, title: 'Log In' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Login.vue')
      }, {
        path: 'logout',
        name: 'auth.logout',
        meta: { auth: true, title: 'Log Out' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Logout.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  /**
   * Clear Validation Errors.
   */
  Object.keys(store.state).forEach(module => {
    let exemptModules = ['authentication', 'overview'];
    if(!exemptModules.includes(module)) {
      store.dispatch(`${module}/clearErrors`)
    }
  })

  /**
   * Authentication
   */

  if (to.matched.some(r => r.meta.auth)) {
    if (from.matched.some(r => r.meta.auth)) {
      return next()
    }

    store.dispatch('authentication/check')
      .then(response => {
        return next()
      })
      .catch(() => next({ name: 'auth.login', query: { next: from.path } }))
  } else if (to.matched.some(r => !r.meta.auth)) {
    if (from.matched.some(r => !r.meta.auth)) {
      return next()
    } else {
      store.dispatch('authentication/check')
        .then(() => next({ name: 'dash.index' }))
        .catch(() => next())
    }
  } else {
    next()
  }
})

export default router