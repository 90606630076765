import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios'

import App from './App'
import router from './router'
import store from './store'

Vue.use(BootstrapVueIcons)
Vue.use(BootstrapVue)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAddressCard, faAngleLeft, faAngleRight, faArrowRight, faColumns, faFileInvoice,
  faFileInvoiceDollar, faHandSparkles, faHotel, faKey, faPen, faPlus, faPumpSoap,
  faRedo, faShippingFast, faStopwatch, faTasks, faTrash, faUmbrellaBeach, faUpload, faDownload,
  faUserPlus, faUsers, faTimes, faEnvelope, faPaperPlane, faFileImport, faFlag, faMapMarked, faCheck, faList,
} from '@fortawesome/free-solid-svg-icons'
import {
  faSnapchat, faFacebook, faGoogle, faTiktok, faPinterest
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAddressCard);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faArrowRight);
library.add(faColumns);
library.add(faHandSparkles);
library.add(faHotel);
library.add(faFileInvoice);
library.add(faFileInvoiceDollar);
library.add(faKey);
library.add(faRedo);
library.add(faPen);
library.add(faPlus);
library.add(faPumpSoap);
library.add(faShippingFast);
library.add(faStopwatch);
library.add(faTasks);
library.add(faTrash);
library.add(faUmbrellaBeach);
library.add(faUpload);
library.add(faUsers);
library.add(faUserPlus);
library.add(faTimes);
library.add(faDownload);
library.add(faEnvelope);
library.add(faPaperPlane);
library.add(faFileImport);
library.add(faFlag);
library.add(faMapMarked);
library.add(faCheck);
library.add(faColumns);
library.add(faList);

// Brands
library.add(faFacebook);
library.add(faGoogle);
library.add(faSnapchat);
library.add(faTiktok);
library.add(faPinterest);

Vue.component('fa-icon', FontAwesomeIcon)

window.axios = axios
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL
window.axios.defaults.headers.Accept = 'application/json'
window.axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.withCredentials = true

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
