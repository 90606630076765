import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const namespaced = true

export default {
  namespaced,
  state: () => ({
    current: {
      user: null,
    },
    login: {
      loading: false,
      errors: {}
    },
    logout: {
      loading: false,
      errors: {}
    },
    xsrf: {
      loading: false,
      token: false,
      errors: {}
    }
  }),
  mutations,
  getters,
  actions
}
