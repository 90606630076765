<template>
  <b-navbar fixed="top" type="dark" variant="primary">
    <b-navbar-brand @click="$root.$emit('sidebar::toggle')" class="d-block d-md-none">
      <b-icon class="text-white mr-2" font-scale="1.3" icon="layout-sidebar-inset"></b-icon>
    </b-navbar-brand>
    <b-navbar-brand @click="$router.go(-1)" class="d-block d-md-none">
      <b-icon class="text-white mr-2" font-scale="1.3" icon="arrow-left"></b-icon>
    </b-navbar-brand>
    <b-navbar-brand to="/" class="logo-container">
      <img alt="Better Living Outdoors" height="42" src="../assets/logo-white.png" />
      <span class="sr-only">DW Aligners</span>
    </b-navbar-brand>
    <b-navbar-brand @click="$router.go()" class="ml-auto d-block d-md-none">
      <b-icon class="text-white mr-2" font-scale="1.3" icon="arrow-repeat"></b-icon>
    </b-navbar-brand>
    <b-navbar-nav class="ml-md-auto">
      <b-nav-item-dropdown right variant="light">
        <template v-slot:button-content>
          <span class="text-light">{{ currentUser.name.split(' ')[0] }}</span>
        </template>
        <b-dropdown-item :to="{name: 'auth.logout'}">Sign Out</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import currentUser from '../mixins/currentUser'

export default {
  mixins: [currentUser]
}
</script>

<style>
    @media screen and (max-width: 430px) {
        .logo-container {
            display: none;
        }
    }
</style>
