<template>
  <b-col :class="{'d-flex': showSidebar, 'd-none': !showSidebar}" class="bg-light d-none d-md-block sidebar" cols="12" md="3" xl="2">
    <div class="mh-100 sidebar-scroll">
      <b-nav class="mb-4" vertical>
        <b-nav-text class="nav-header">Overview</b-nav-text>
        <b-nav-item class="text-dark" to="/" :active="routeIdentifierActive('overview')"><b-icon class="mr-2" font-scale="1.4" icon="house" /> Overview</b-nav-item>
      </b-nav>
      
      <b-nav class="mb-4" vertical>
        <b-nav-text class="nav-header">Financial</b-nav-text>
        <b-nav-item class="text-dark" to="/financial" :active="routeIdentifierActive('financial')"><b-icon class="mr-2" font-scale="1.4" icon="wallet2" /> Financial</b-nav-item>
        <b-nav-item class="text-dark" to="/platform" :active="routeIdentifierActive('platform')"><b-icon class="mr-2" font-scale="1.4" icon="diagram2" /> Platform</b-nav-item>
        <b-nav-item class="text-dark" to="/marketing-costs" :active="routeIdentifierActive('marketing-costs')"><b-icon class="mr-2" font-scale="1.4" icon="receipt" /> Marketing Costs</b-nav-item>
        <b-nav-item class="text-dark" to="/marketing-revenue" :active="routeIdentifierActive('marketing-revenue')"><b-icon class="mr-2" font-scale="1.4" icon="cash-stack" /> Marketing Revenue</b-nav-item>
        <b-nav-item class="text-dark" to="/conversions" :active="routeIdentifierActive('conversions')"><b-icon class="mr-2" font-scale="1.4" icon="graph-up" /> Conversions</b-nav-item>
      </b-nav>

      <b-nav class="mb-4" vertical>
        <b-nav-text class="nav-header">Time To Purchase </b-nav-text>
        <b-nav-item class="text-dark" to="/time-to-sale/scan-appointments" :active="routeIdentifierActive('scan-appointments')"><b-icon class="mr-2" font-scale="1.4" icon="circle" /> Scan Appointments</b-nav-item>
        <b-nav-item class="text-dark" to="/time-to-sale/impression-kits" :active="routeIdentifierActive('impression-kits')"><b-icon class="mr-2" font-scale="1.4" icon="circle-half
" /> Impression Kit</b-nav-item>
        <b-nav-item class="text-dark" to="/time-to-sale/aligners" :active="routeIdentifierActive('aligners')"><b-icon class="mr-2" font-scale="1.4" icon="circle-fill" /> Aligners</b-nav-item>
      </b-nav>

      <b-nav class="mb-4" vertical>
        <b-nav-text class="nav-header">Reports</b-nav-text>
        <b-nav-item class="text-dark" to="/reports/postcode" :active="routeIdentifierActive('postcode-report')"><b-icon class="mr-2" font-scale="1.4" icon="map" /> Postcode</b-nav-item>
        <b-nav-item class="text-dark" to="/reports/tabeo" :active="routeIdentifierActive('tabeo-report')"><b-icon class="mr-2" font-scale="1.4" icon="check2-square" /> Tabeo</b-nav-item>
      </b-nav>

    </div>
  </b-col>
</template>

<script>
export default {
  data () {
    return { showSidebar: false }
  },
  mounted () {
    this.$root.$on('sidebar::toggle', () => {
      this.showSidebar = !this.showSidebar
    })
    this.$root.$on('sidebar::set', (value) => {
      this.showSidebar = value
    })
  },
  methods: {
    routeIdentifierActive(routeIdentifier) {
      if(!this.$route.meta.hasOwnProperty('routeIdentifier')) return false
      return (routeIdentifier == this.$route.meta.routeIdentifier)
    }
  }
}
</script>

<style lang="scss" scoped>
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 68px 0 0;
    z-index: 100;
    border-right: 1px solid darken($light, 10%);

    .sidebar-scroll {
      overflow-y: scroll;
      width: 100%;
    }

    ul.nav {
      li.nav-header {
        color: lighten($dark, 20%);
        font-size: 0.8rem;
        font-weight: bold;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      li.nav-item {
        text-overflow: ellipsis;
        width: 100%;

        a {
          color: $dark;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          svg {
            color: lighten($dark, 10%);
            margin-top: -.2em;
          }

          &:not(.active):hover {
            background: darken($light, 5%);
          }

          &.active {
            background: $primary;
            color: $light;

            svg {
              color: $light;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 765px) {
    .sidebar {
      border-right: 0;
    }
  }
</style>
