import flatMap from 'lodash/flatMap'
import flatten from 'lodash/flatten'
import pick from 'lodash/pick'
import map from 'lodash/map'
import omit from 'lodash/omit'
import union from 'lodash/union'
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  XSRF_TOKEN_REQUEST,
  XSRF_TOKEN_SUCCESS,
  XSRF_TOKEN_FAILURE,
} from './types'

export default {
  check ({ commit }) {
    commit(AUTH_LOGIN_REQUEST)
    return window.axios.get('/auth/check')
      .then(response => {
        commit(AUTH_LOGIN_SUCCESS, response.data)
        return response
      })
      .catch(error => {
        commit(AUTH_LOGIN_FAILURE, { error })
        throw error
      })
  },
  login ({ commit }, payload) {
    commit(AUTH_LOGIN_REQUEST)
    return window.axios.post('/auth/login', payload)
      .then(response => {
        commit(AUTH_LOGIN_SUCCESS, response.data)
        return response
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          return commit(AUTH_LOGIN_FAILURE, { errors: error.response.data.errors })
        }

        commit(AUTH_LOGIN_FAILURE, { error })
        throw error
      })
  },
  logout ({ commit }) {
    commit(AUTH_LOGOUT_REQUEST)
    return window.axios.post('/auth/logout')
      .then(response => {
        commit(AUTH_LOGOUT_SUCCESS)
        return response
      })
      .catch(error => {
        commit(AUTH_LOGOUT_FAILURE, error)
        throw error
      })
  },
  token ({ commit }) {
    commit(XSRF_TOKEN_REQUEST)
    return window.axios.get('/csrf-cookie')
      .then(response => {
        commit(XSRF_TOKEN_SUCCESS)
        return response
      })
      .catch(error => {
        commit(XSRF_TOKEN_FAILURE, { error })
        throw error
      })
  }
}
