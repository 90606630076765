<template>
  <span v-b-popover.hover.top="fullDate">{{ fromNow }}</span>
</template>

<script>
import momentMixin from '../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  computed: {
    fromNow () {
      return this.moment(this.date).fromNow()
    },
    fullDate () {
      return this.moment(this.date).format(`Do MMMM YYYY${!this.omitTime ? ' HH:mm:ss' : ''}`)
    }
  },
  props: {
    date: {
      required: true,
      type: String
    },
    omitTime: {
      default: false,
      type: Boolean,
    }
  }
}
</script>

<style>

</style>
